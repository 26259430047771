import React, { Component } from "react";
import {Link} from "gatsby";

class Topbar extends Component {
  render() {
    return (
      <div className="topbar-one">
        <div className="container">
          <div className="topbar-one__left">
            <a href="#">info@cssnacademy.org</a>
            <a href="#">+256 774 670173</a>
          </div>
          <div className="topbar-one__right">
            <a href="https://portal.cssnetwork.org/login" target="_blank">Login</a>
            {/*<Link to="/auth/login">Login</Link>*/}
            <a href="https://portal.cssnetwork.org/register"  target="_blank">Register</a>
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
