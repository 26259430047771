import React, {Component} from "react";
import cta1 from "../assets/images/cta-1.jpg";

class CallToActionThree extends Component {
    render() {
        return (
            <section className="cta-three bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 clearfix">
                            <img src={cta1} className="cta-three__image" alt=""/>
                        </div>
                        <div className="col-lg-6">
                            <div className="cta-three__content">
                                <div className="block-title text-left">
                                    <h2 className="block-title__title">
                                        Benefits of learning with CSSNA
                                    </h2>
                                </div>
                                <p className="cta-three__text text-justify">
                                    CSSN runs a CSS academy to support youth led and youth focused CSOs to be more resilient beyond the internal and external shrinking factors.
                                 <br/>
                                 With finance support from Dreamtown and NAC, CSSN will focus to deliver a 12 months programme “ Open Civic Space for Youth “ in Arua District.

                                </p>
                                <div className="cta-three__single-wrap">
                                    <div className="cta-three__single">
                                        <i className="kipso-icon-strategy"></i>
                                        <p className="cta-three__single-text">
                                            Developmental Meetings
                                        </p>
                                    </div>
                                    <div className="cta-three__single">
                                        <i className="kipso-icon-training"></i>
                                        <p className="cta-three__single-text">Live Learning</p>
                                    </div>

                                    <div className="cta-three__single">
                                        <i className="kipso-icon-human-resources"></i>
                                        <p className="cta-three__single-text">Expert Facilitators</p>
                                    </div>
                                </div>
                                {/*<a href="#none" className="thm-btn">*/}
                                {/*    Veiw Sessions*/}
                                {/*</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default CallToActionThree;
