import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import AboutOne from "../../components/AboutOne";
import Footer from "../../components/Footer";
import React, {useEffect, useState} from "react";
import axios from 'axios';
const LoginPage = () => {

    // const [data, setData] = useState([]);
    // const [data2, setData2] = useState('Data two');
    //
    // useEffect(() => {
    //     fetchData()
    // }, []);

    // const fetchData = async () => {
    //     // setRefreshig(true);
    //     try {
    //         const resp = await axios.get("https://autosoftlabs.com/");
    //         setData(resp.data);
    //         console.log(resp.data)
    //         // setLoading(false)
    //     } catch (err) {
    //         console.error(err);
    //     }
    //     // setRefreshig(false)
    // };


    return (
        <Layout pageTitle="CSSNA | Login">
            <NavOne/>

            <div className="container">
                {/*{data}*/}
            </div>

            <Footer/>
        </Layout>
    );
};

export default LoginPage;
