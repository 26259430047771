import React from "react";
import blog1 from "../assets/images/blog-1-1.jpg";
import blog2 from "../assets/images/blog-1-2.jpg";
import blog3 from "../assets/images/blog-1-3.jpg";

const BlogThree = () => {
    return (
        <section className="blog-one  blog-one__home-two">
            <div className="container">
                <div className="block-title text-center">
                    <h2 className="block-title__title">
                        Our latest stories
                    </h2>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="blog-one__single">
                            <div className="blog-one__image">
                                <img src={blog1} alt=""/>
                                <a className="blog-one__plus" href="news-details">
                                    <i className="kipso-icon-plus-symbol"></i>
                                </a>
                            </div>
                            <div className="blog-one__content text-center">

                                <h2 className="blog-one__title">
                                    <a href="news-details">
                                        Kirombe’s Unending Floods A Nightmare To Residents
                                    </a>
                                </h2>
                                <p className="blog-one__text">
                                    Manyattta is a zone in Kirombe, a Kampala suburb. Residents famously know the area
                                    as where flooding never ceases.
                                </p>
                                <a href="news-details" className="blog-one__link">
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="blog-one__single">
                            <div className="blog-one__image">
                                <img src={blog2} alt=""/>
                                <a className="blog-one__plus" href="news-details">
                                    <i className="kipso-icon-plus-symbol"></i>
                                </a>
                            </div>
                            <div className="blog-one__content text-center">

                                <h2 className="blog-one__title">
                                    <a href="news-details">
                                        Kirombe’s Unending Floods A Nightmare To Residents
                                    </a>
                                </h2>
                                <p className="blog-one__text">
                                    Manyattta is a zone in Kirombe, a Kampala suburb. Residents famously know the area
                                    as where flooding never ceases.
                                </p>
                                <a href="news-details" className="blog-one__link">
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="blog-one__single">
                            <div className="blog-one__image">
                                <img src={blog3} alt=""/>
                                <a className="blog-one__plus" href="news-details">
                                    <i className="kipso-icon-plus-symbol"></i>
                                </a>
                            </div>
                            <div className="blog-one__content text-center">

                                <h2 className="blog-one__title">
                                    <a href="news-details">
                                        Kirombe’s Unending Floods A Nightmare To Residents
                                    </a>
                                </h2>
                                <p className="blog-one__text">
                                    Manyattta is a zone in Kirombe, a Kampala suburb. Residents famously know the area
                                    as where flooding never ceases.
                                </p>
                                <a href="news-details" className="blog-one__link">
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default BlogThree;
