import React from "react";

const NotFound = () => {
  return (
    <div>

      <section className="contact-one">
        <div className="container">
          <h2 className="contact-one__title text-center">
            Page Not Found
          </h2>

          <div className="col-lg-12">
            <div className="text-center">
              <a href="/" className="thm-btn cta-four__btn">
                Home
              </a>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default NotFound;
