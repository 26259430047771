import React, {Component} from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import about1 from "../assets/images/about-1-1.jpg";

export default class AboutTwo extends Component {
    constructor() {
        super();
        this.state = {
            startCounter: false
        };
    }

    onVisibilityChange = (isVisible) => {
        if (isVisible) {
            this.setState({startCounter: true});
        }
    };

    render() {
        return (
            <section className="about-two">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-two__content">
                                <div className="block-title text-left">
                                    <h2 className="block-title__title">
                                        Welcome to CSS Academy
                                    </h2>
                                </div>
                                <p className="about-two__text text-justify">
                                    With a mission to provide technical assistance through customized training,
                                    mentorship and education programmes for early start CSOs in strategic organisational
                                    systems development, website development, proposal development and financial systems
                                    management.
                                </p>

                                <div className="about-two__single-wrap">
                                    <div className="about-two__single">
                                        <div className="about-two__single-content">
                                            <p className="about-two__single-text">
                                                "improving
                                                organisation systems"
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <a href="/about" className="thm-btn">
                                    Learn More
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-6 d-flex justify-content-xl-end justify-content-sm-center">
                            <div className="about-two__image">
                                <span className="about-two__image-dots"></span>
                                <img src={about1} alt=""/>
                                <div className="about-two__count">
                                    <div className="about-two__count-text">
                                        Helped
                                        <span className="counter">
                                            <VisibilitySensor
                                                onChange={this.onVisibilityChange}
                                                offset={{top: 10}}
                                                delayedCall
                                            >
                                            <CountUp end={this.state.startCounter ? 4770 : 0}/>
                                          </VisibilitySensor>
                                        </span>
                                        +more
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
