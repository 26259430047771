import React, {useEffect, useState} from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import course1 from "../assets/images/course-1-1.jpg";
import course2 from "../assets/images/course-1-2.jpg";
import course3 from "../assets/images/course-1-3.jpg";
import course4 from "../assets/images/course-1-4.jpg";
import course5 from "../assets/images/course-1-5.jpg";
import course6 from "../assets/images/course-1-6.jpg";
import team1 from "../assets/images/team-1-1.jpg";
import team2 from "../assets/images/team-1-2.jpg";
import team3 from "../assets/images/team-1-3.jpg";
import team4 from "../assets/images/team-1-4.jpg";
import team5 from "../assets/images/team-1-5.jpg";
import team6 from "../assets/images/team-1-6.jpg";
import moment from "moment";
import api from "../constants/api";

const CourseOne = () => {
  const params = {
    slidesPerView: 3,
    loop: true,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },

    // Responsive breakpoints
    breakpoints: {
      1024: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      640: {
        slidesPerView: 2
      },
      320: {
        slidesPerView: 1
      }
    }
  };


  // states
  const [meetings, setMeetings] = useState([])

  useEffect(() => {
    countRecordings()
  }, [])


  const countRecordings = async () => {
    const {data} = await api.get('/recordings')
    setMeetings(data)
  }

  return (
    <div>
      <section className="course-one__top-title home-one">
        <div className="container">
          <div className="block-title mb-0">
            <h2 className="block-title__title">
              Our recent sessions
            </h2>
          </div>
        </div>
        <div className="course-one__top-title__curve"></div>
      </section>

      <section className="course-one course-one__teacher-details home-one">
        <div className="container">
          <div className="course-one__carousel">
            <Swiper {...params}>

              {meetings.map((meets) =>
                  <div className="col-lg-4">
                    <div className="course-one__single color-2">
                      <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                        <iframe
                            title="test"
                            className="embed-responsive-item"
                            src={meets.source}
                        />
                      </div>
                      <div className="course-one__content">
                        <a href="#none" className="course-one__category">
                          development
                        </a>

                        <h2 className="course-one__title">
                          <a href="/course-details">{meets.topic}</a>
                        </h2>

                        <div className="course-one__meta">
                          <a href="/course-details">
                            <i className="far fa-clock"></i> {moment(meets.start_time).format('LLL')}
                          </a>
                        </div>
                        {/*<a href="#none" className="course-one__link">*/}
                        {/*  See more*/}
                        {/*</a>*/}
                      </div>
                    </div>
                  </div>
              )}

              {/*{meetings.map((meets) =>*/}
              {/*    <div className="item">*/}
              {/*      <div className="course-one__single color-2">*/}
              {/*        <div className="course-one__image">*/}
              {/*          <img src={course1} alt="" />*/}
              {/*          <iframe*/}
              {/*              title="test"*/}
              {/*              className="embed-responsive-item"*/}
              {/*              src={meets.source}*/}
              {/*          />*/}
              {/*        </div>*/}
              {/*        <div className="course-one__content">*/}
              {/*          <a href="#none" className="course-one__category">*/}
              {/*            development*/}
              {/*          </a>*/}

              {/*          <h2 className="course-one__title">*/}
              {/*            <a href="/course-details">{meets.topic}</a>*/}
              {/*          </h2>*/}

              {/*          <div className="course-one__meta">*/}
              {/*            <a href="/course-details">*/}
              {/*              <i className="far fa-clock"></i> {moment(meets.start_time).format('LLL')}*/}
              {/*            </a>*/}
              {/*          </div>*/}
              {/*          <a href="#none" className="course-one__link">*/}
              {/*            See more*/}
              {/*          </a>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*)}*/}




              {/*<div className="item">*/}
              {/*  <div className="course-one__single color-3">*/}
              {/*    <div className="course-one__image">*/}
              {/*      <img src={course1} alt="" />*/}
              {/*    </div>*/}
              {/*    <div className="course-one__content">*/}
              {/*      <a href="#none" className="course-one__category">*/}
              {/*        development*/}
              {/*      </a>*/}

              {/*      <h2 className="course-one__title">*/}
              {/*        <a href="/course-details">New react bootcamp</a>*/}
              {/*      </h2>*/}

              {/*      <div className="course-one__meta">*/}
              {/*        <a href="/course-details">*/}
              {/*          <i className="far fa-clock"></i> Monday, 14 March 2022*/}
              {/*        </a>*/}
              {/*      </div>*/}
              {/*      <a href="#none" className="course-one__link">*/}
              {/*        See more*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<div className="item">*/}
              {/*  <div className="course-one__single color-4">*/}
              {/*    <div className="course-one__image">*/}
              {/*      <img src={course1} alt="" />*/}
              {/*    </div>*/}
              {/*    <div className="course-one__content">*/}
              {/*      <a href="#none" className="course-one__category">*/}
              {/*        development*/}
              {/*      </a>*/}

              {/*      <h2 className="course-one__title">*/}
              {/*        <a href="/course-details">New react bootcamp</a>*/}
              {/*      </h2>*/}

              {/*      <div className="course-one__meta">*/}
              {/*        <a href="/course-details">*/}
              {/*          <i className="far fa-clock"></i> Monday, 14 March 2022*/}
              {/*        </a>*/}
              {/*      </div>*/}
              {/*      <a href="#none" className="course-one__link">*/}
              {/*        See more*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<div className="item">*/}
              {/*  <div className="course-one__single color-5">*/}
              {/*    <div className="course-one__image">*/}
              {/*      <img src={course1} alt="" />*/}
              {/*    </div>*/}
              {/*    <div className="course-one__content">*/}
              {/*      <a href="#none" className="course-one__category">*/}
              {/*        development*/}
              {/*      </a>*/}

              {/*      <h2 className="course-one__title">*/}
              {/*        <a href="/course-details">New react bootcamp</a>*/}
              {/*      </h2>*/}

              {/*      <div className="course-one__meta">*/}
              {/*        <a href="/course-details">*/}
              {/*          <i className="far fa-clock"></i> Monday, 14 March 2022*/}
              {/*        </a>*/}
              {/*      </div>*/}
              {/*      <a href="#none" className="course-one__link">*/}
              {/*        See more*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<div className="item">*/}
              {/*  <div className="course-one__single color-6">*/}
              {/*    <div className="course-one__image">*/}
              {/*      <img src={course1} alt="" />*/}
              {/*    </div>*/}
              {/*    <div className="course-one__content">*/}
              {/*      <a href="#none" className="course-one__category">*/}
              {/*        development*/}
              {/*      </a>*/}

              {/*      <h2 className="course-one__title">*/}
              {/*        <a href="/course-details">New react bootcamp</a>*/}
              {/*      </h2>*/}

              {/*      <div className="course-one__meta">*/}
              {/*        <a href="/course-details">*/}
              {/*          <i className="far fa-clock"></i> Monday, 14 March 2022*/}
              {/*        </a>*/}
              {/*      </div>*/}
              {/*      <a href="#none" className="course-one__link">*/}
              {/*        See more*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}



            </Swiper>
          </div>
        </div>
      </section>
    </div>
  );
};
export default CourseOne;
