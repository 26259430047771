import React from "react";
import Layout from "../components/Layout";
import Topbar from "../components/Topbar";
import NavOne from "../components/NavOne";
import SliderOne from "../components/SliderOne";
import Footer from "../components/Footer";
import AboutTwo from "../components/AboutTwo";
import CourseOne from "../components/CourseOne";
import VideoTwo from "../components/VideoTwo";
import CountdownKipso from "../components/CountdownKipso";
import CourseCatOne from "../components/CourseCatOne";
import CallToActionThree from "../components/CallToActionThree";
import BrandsTwo from "../components/BrandsTwo";
import BlogTwo from "../components/BlogTwo";
import CallToActionFour from "../components/CallToActionFour";
import SubscribeOne from "../components/SubscribeOne";
import NavTwo from "../components/NavTwo";
import SliderTwo from "../components/SliderTwo";
import CallToActionFive from "../components/CallToActionFive";
import CallToActionTwo from "../components/CallToActionTwo";
import BlogThree from "../components/BlogThree";

const HomePage = () => (
    <Layout pageTitle="CSS Network Academy">
        <Topbar/>
        <NavOne/>
        <SliderTwo/>
        <AboutTwo/>
        <CourseOne/>
        <CallToActionThree/>
        {/*<BlogThree/>*/}
        <BrandsTwo/>
        <CallToActionFive/>
        <Footer/>
    </Layout>
);

export default HomePage;
