import React, {useEffect} from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import NotFound from "../components/NotFound";

const GalleryPage = () => {

    return (
        <Layout pageTitle="CSSNA | Contact">
            <NavOne />
            {/*<PageHeader title="" />*/}
            <NotFound />
            <Footer />
        </Layout>
    );
};

export default GalleryPage;
