import React, {useEffect, useState} from "react";
import { Link } from "gatsby";
// import course1 from "../assets/images/course-1-1.jpg";
// import course2 from "../assets/images/course-1-2.jpg";
// import course3 from "../assets/images/course-1-3.jpg";
// import course4 from "../assets/images/course-1-4.jpg";
// import course5 from "../assets/images/course-1-5.jpg";
// import course6 from "../assets/images/course-1-6.jpg";
// import team1 from "../assets/images/team-1-1.jpg";
// import team2 from "../assets/images/team-1-2.jpg";
// import team3 from "../assets/images/team-1-3.jpg";
// import team4 from "../assets/images/team-1-4.jpg";
// import team5 from "../assets/images/team-1-5.jpg";
// import team6 from "../assets/images/team-1-6.jpg";
// import Swiper from "react-id-swiper";
import api from "../constants/api";
import moment from 'moment';

const Courses = props => {

  // states
  const [meetings, setMeetings] = useState([])

  useEffect(() => {
    countRecordings()
  }, [])


  const countRecordings = async () => {
    const {data} = await api.get('/recordings')
    console.log(data);
    setMeetings(data)
  }

  return (
    <section className="course-one course-page">
      <div className="container">
        <div className="row">

          {/*// component*/}
          {meetings.map((meets) =>
              <div className="col-lg-4">
                <div className="course-one__single color-2">
                  <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                    <iframe
                        title="test"
                        className="embed-responsive-item"
                        src={meets.source}
                    />
                  </div>
                  <div className="course-one__content">
                    <a href="#none" className="course-one__category">
                      development
                    </a>

                    <h2 className="course-one__title">
                      <a href="/course-details">{meets.topic}</a>
                    </h2>

                    <div className="course-one__meta">
                      <a href="/course-details">
                        <i className="far fa-clock"></i> {moment(meets.start_time).format('LLL')}
                      </a>
                    </div>
                    {/*<a href="#none" className="course-one__link">*/}
                    {/*  See more*/}
                    {/*</a>*/}
                  </div>
                </div>
              </div>
          )}


          {/*<div className="col-lg-4">*/}
          {/*  <div className="course-one__single color-3">*/}
          {/*    <div className="course-one__image">*/}
          {/*      <img src={course1} alt="" />*/}
          {/*    </div>*/}
          {/*    <div className="course-one__content">*/}
          {/*      <a href="#none" className="course-one__category">*/}
          {/*        development*/}
          {/*      </a>*/}

          {/*      <h2 className="course-one__title">*/}
          {/*        <a href="/course-details">New react bootcamp</a>*/}
          {/*      </h2>*/}

          {/*      <div className="course-one__meta">*/}
          {/*        <a href="/course-details">*/}
          {/*          <i className="far fa-clock"></i> Monday, 14 March 2022*/}
          {/*        </a>*/}
          {/*      </div>*/}
          {/*      <a href="#none" className="course-one__link">*/}
          {/*        See more*/}
          {/*      </a>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="col-lg-4">*/}
          {/*  <div className="course-one__single color-4">*/}
          {/*    <div className="course-one__image">*/}
          {/*      <img src={course1} alt="" />*/}
          {/*    </div>*/}
          {/*    <div className="course-one__content">*/}
          {/*      <a href="#none" className="course-one__category">*/}
          {/*        development*/}
          {/*      </a>*/}

          {/*      <h2 className="course-one__title">*/}
          {/*        <a href="/course-details">New react bootcamp</a>*/}
          {/*      </h2>*/}

          {/*      <div className="course-one__meta">*/}
          {/*        <a href="/course-details">*/}
          {/*          <i className="far fa-clock"></i> Monday, 14 March 2022*/}
          {/*        </a>*/}
          {/*      </div>*/}
          {/*      <a href="#none" className="course-one__link">*/}
          {/*        See more*/}
          {/*      </a>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="col-lg-4">*/}
          {/*  <div className="course-one__single color-5">*/}
          {/*    <div className="course-one__image">*/}
          {/*      <img src={course1} alt="" />*/}
          {/*    </div>*/}
          {/*    <div className="course-one__content">*/}
          {/*      <a href="#none" className="course-one__category">*/}
          {/*        development*/}
          {/*      </a>*/}

          {/*      <h2 className="course-one__title">*/}
          {/*        <a href="/course-details">New react bootcamp</a>*/}
          {/*      </h2>*/}

          {/*      <div className="course-one__meta">*/}
          {/*        <a href="/course-details">*/}
          {/*          <i className="far fa-clock"></i> Monday, 14 March 2022*/}
          {/*        </a>*/}
          {/*      </div>*/}
          {/*      <a href="#none" className="course-one__link">*/}
          {/*        See more*/}
          {/*      </a>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="col-lg-4">*/}
          {/*  <div className="course-one__single color-6">*/}
          {/*    <div className="course-one__image">*/}
          {/*      <img src={course1} alt="" />*/}
          {/*    </div>*/}
          {/*    <div className="course-one__content">*/}
          {/*      <a href="#none" className="course-one__category">*/}
          {/*        development*/}
          {/*      </a>*/}

          {/*      <h2 className="course-one__title">*/}
          {/*        <a href="/course-details">New react bootcamp</a>*/}
          {/*      </h2>*/}

          {/*      <div className="course-one__meta">*/}
          {/*        <a href="/course-details">*/}
          {/*          <i className="far fa-clock"></i> Monday, 14 March 2022*/}
          {/*        </a>*/}
          {/*      </div>*/}
          {/*      <a href="#none" className="course-one__link">*/}
          {/*        See more*/}
          {/*      </a>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="col-lg-4">*/}
          {/*  <div className="course-one__single color-2">*/}
          {/*    <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">*/}
          {/*      <iframe*/}
          {/*          title="test"*/}
          {/*          className="embed-responsive-item"*/}
          {/*          src="https://www.youtube.com/embed/V3F03bbldWM"*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*    <div className="course-one__content">*/}
          {/*      <a href="#none" className="course-one__category">*/}
          {/*        development*/}
          {/*      </a>*/}

          {/*      <h2 className="course-one__title">*/}
          {/*        <a href="/course-details">New react bootcamp</a>*/}
          {/*      </h2>*/}

          {/*      <div className="course-one__meta">*/}
          {/*        <a href="/course-details">*/}
          {/*          <i className="far fa-clock"></i> Monday, 14 March 2022*/}
          {/*        </a>*/}
          {/*      </div>*/}
          {/*      <a href="#none" className="course-one__link">*/}
          {/*        See more*/}
          {/*      </a>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}


        </div>
        <div className="post-pagination">
          <a href="#none">
            <i className="fa fa-angle-double-left"></i>
          </a>
          <a className="active" href="#none">
            1
          </a>
          <a href="#none">2</a>
          <a href="#none">3</a>
          <a href="#none">4</a>
          <a href="#none">
            <i className="fa fa-angle-double-right"></i>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Courses;
