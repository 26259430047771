import React from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import qoute1 from "../assets/images/qoute-1-1.png";

const TestimonialOne = () => {
    const params = {
        slidesPerView: 3,
        loop: true,
        speed: 1000,
        spaceBetween: 30,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },

        // Responsive breakpoints
        breakpoints: {
            1024: {
                slidesPerView: 3
            },
            768: {
                slidesPerView: 2
            },
            640: {
                slidesPerView: 2
            },
            320: {
                slidesPerView: 1
            }
        }
    };

    return (
        <section className="testimonials-one testimonials-one__home-three">
            <div className="container">
                <div className="block-title text-center">
                    <h2 className="block-title__title">
                        Testimonials
                    </h2>
                </div>
                <div className="testimonials-one__carousel">
                    <Swiper {...params}>
                        <div className="item">
                            <div className="testimonials-one__single">
                                <div className="testimonials-one__qoute">
                                    <img src={qoute1} alt=""/>
                                </div>
                                <p className="testimonials-one__text">
                                    The sessions were so educative and am glad i atended though not all but most of
                                    them. Thanks for thinking about this program.
                                </p>
                                {/*<img src={team1} alt="" className="testimonials-one__img" />*/}
                                <h3 className="testimonials-one__name">Okwir</h3>
                                <p className="testimonials-one__designation">Attendee</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="testimonials-one__single">
                                <div className="testimonials-one__qoute">
                                    <img src={qoute1} alt=""/>
                                </div>
                                <p className="testimonials-one__text">
                                    You chose not to grow alone by helping other similar organisations. We are so
                                    greatful for your service.
                                </p>
                                {/*<img src={team4} alt="" className="testimonials-one__img" />*/}
                                <h3 className="testimonials-one__name">Joram</h3>
                                <p className="testimonials-one__designation">Attendee</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="testimonials-one__single">
                                <div className="testimonials-one__qoute">
                                    <img src={qoute1} alt=""/>
                                </div>
                                <p className="testimonials-one__text">
                                    Looking forward for continued mentorship. Thanks CSSA, NAC and Dreamtown for the
                                    great work being done
                                </p>
                                {/*<img src={team2} alt="" className="testimonials-one__img" />*/}
                                <h3 className="testimonials-one__name">Otim</h3>
                                <p className="testimonials-one__designation">Attendee</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="testimonials-one__single">
                                <div className="testimonials-one__qoute">
                                    <img src={qoute1} alt=""/>
                                </div>
                                <p className="testimonials-one__text">
                                    I will need more sessions to keep up with updates of ongoing activities. I found
                                    them helpful. Thank you
                                </p>
                                {/*<img src={team3} alt="" className="testimonials-one__img" />*/}
                                <h3 className="testimonials-one__name">Jesca</h3>
                                <p className="testimonials-one__designation">Delegate</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="testimonials-one__single">
                                <div className="testimonials-one__qoute">
                                    <img src={qoute1} alt=""/>
                                </div>
                                <p className="testimonials-one__text">
                                    You chose not to grow alone by helping other similar organisations. We are so
                                    greatful for your service.
                                </p>
                                {/*<img src={team4} alt="" className="testimonials-one__img" />*/}
                                <h3 className="testimonials-one__name">Joram</h3>
                                <p className="testimonials-one__designation">Attendee</p>
                            </div>
                        </div>
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default TestimonialOne;
