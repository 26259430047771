import React, {Component} from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import about1 from "../assets/images/about-2-1.jpg";

export default class AboutThree extends Component {
    constructor() {
        super();
        this.state = {
            startCounter: false
        };
    }

    onVisibilityChange = (isVisible) => {
        if (isVisible) {
            this.setState({startCounter: true});
        }
    };

    render() {
        return (
            <section className="about-two">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-two__content">
                                <div className="block-title text-left">
                                    <h2 className="block-title__title">
                                        Our strategic objective
                                    </h2>
                                </div>
                                <p className="about-two__text text-justify">
                                    Civil Society Strengthening Network (CSSN) and partners are implementing
                                    project <b> “Open Civic Space
                                    for Youth “</b> whose strategic objective is to strengthen organisational resilience and
                                    ease operating environment for
                                    youth-led CSO’s in Arua District.
                                </p>

                                {/*<div className="about-two__single-wrap">*/}
                                {/*    <div className="about-two__single">*/}
                                {/*        <div className="about-two__single-content">*/}
                                {/*            <p className="about-two__single-text">*/}
                                {/*                "improving*/}
                                {/*                organisation systems"*/}
                                {/*            </p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}

                                {/*</div>*/}

                                {/*<a href="/about" className="thm-btn">*/}
                                {/*    Learn More*/}
                                {/*</a>*/}
                            </div>
                        </div>
                        <div className="col-xl-6 d-flex justify-content-xl-end justify-content-sm-center">
                            <div className="">
                                {/*<span className="about-two__image-dots"></span>*/}
                                <img src={about1} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
