import React from "react";
import Swiper from "react-id-swiper";
import brand1 from "../assets/images/brands/brand-1.png";
// import brand2 from "../assets/images/brands/brand-2.png";
import brand3 from "../assets/images/brands/brand-3.png";

import "swiper/css/swiper.css";
const BrandsTwo = () => {
  const params = {
    slidesPerView: 2,
    loop: false,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },

    // Responsive breakpoints
    breakpoints: {
      1024: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      640: {
        slidesPerView: 2
      },
      320: {
        slidesPerView: 2
      }
    }
  };

  return (
    <section className="brand-two bg-light">
      <div className="container">
        <div className="block-title">
          <h2 className="block-title__title">Our partners</h2>
        </div>
        <div className="brand-one__carousel">
          <Swiper {...params}>

            <div className="item">
              <img src={brand1} alt="" />
            </div>

            {/*<div className="item">*/}
            {/*  <img src={brand2} alt="" />*/}
            {/*</div>*/}

            <div className="item">
              <img src={brand3} alt="" />
            </div>

            {/*<div className="item">*/}
            {/*  <img src={brand1} alt="" />*/}
            {/*</div>*/}

            {/*/!*<div className="item">*!/*/}
            {/*/!*  <img src={brand2} alt="" />*!/*/}
            {/*/!*</div>*!/*/}

            {/*<div className="item">*/}
            {/*  <img src={brand3} alt="" />*/}
            {/*</div>*/}



          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default BrandsTwo;
