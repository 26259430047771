import React, {useEffect} from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import api from "../constants/api";

const GalleryPage = () => {

    return (
        <Layout pageTitle="CSSNA | Contact">
            <NavOne />
            <PageHeader title="Contact" />
            <Contact />
            <Footer />
        </Layout>
    );
};

export default GalleryPage;
